const num = (range) =>  Math.floor(Math.random()*range)
const Pattern = ({ctx,w,h,amt}) => {
    // Create a pattern, offscreen
  const canvas = document.createElement('canvas');
  const context = canvas.getContext("2d")
  canvas.width = w || 100;
  canvas.height = h || 100;
  // Give the pattern a background color and draw an arc
  context.fillStyle = 'white';
  context.fillRect(0, 0, w, h);
  context.fillStyle = 'black';
  for (var i = 0; i < amt; i++) {
    context.fillRect(num(w), num(h), 2,2);
  }
  context.stroke();

  return ctx.createPattern(canvas, 'repeat');
}

export default Pattern
