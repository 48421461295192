import React, {useState,useEffect} from "react"
import Layout from "./layout"
import { graphql } from "gatsby"
import ImageMapper from 'react-image-mapper';
import Pattern from "../lib/canvas-pattern"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Turn from "./turn.js"

const num = (range) =>  Math.floor(Math.random()*range)
const prc = () =>
  "rgb("+[[255,0,num(255)],[255,num(255),0],
          [0,num(255),255],[0,255,num(255)],
          [num(255),0,255],[num(255),255,0]][num(6)]
          .join(",")+")"

const average = (array) => array.reduce((a, b) => a + b) / array.length;

class MyImagemapper extends ImageMapper {
  drawpoly(coords, fillColor, lineWidth, strokeColor) {
    let x = average(coords.filter((e,i) => i%2===0).map(e => parseInt(Math.floor(e))))
    let y = average(coords.filter((e,i) => i%2!==0).map(e => parseInt(Math.floor(e))))
    var lingrad = this.ctx.createLinearGradient(x-50,y-50,x+50,y+50);
    var drawpattern = Pattern({
      ctx: this.ctx,
      w: 25,
      h: 25,
      amt: 100
    })      // works every time
    let colors = [prc(),prc(),prc(),prc()]
     lingrad.addColorStop(0, colors[0]);
     lingrad.addColorStop(0.5, colors[1]);

     // lingrad.addColorStop(0.5, colors[2]);
     lingrad.addColorStop(1, colors[3]);
		coords = coords.reduce(
			(a, v, i, s) => (i % 2 ? a : [...a, s.slice(i, i + 2)]),
			[]
		);
		this.ctx.fillStyle = drawpattern;//fillColor;
		this.ctx.beginPath();
		this.ctx.lineWidth = lineWidth;
		this.ctx.strokeStyle = strokeColor;
		let first = coords.unshift();
		this.ctx.moveTo(first[0], first[1]);

		coords.forEach(c => this.ctx.lineTo(c[0], c[1]));
		this.ctx.closePath();
		this.ctx.stroke();
		this.ctx.fill();
		this.ctx.fillStyle = drawpattern//this.props.fillColor;
	}
}

function ImageMap({images,maps,global_areas,slug}){
  let [index,setIndex] = useState(0)
  let [count,setCount] = useState(0)
  let startingSize = 0;
  // if (typeof window !== 'undefined')
  //   startingSize = Math.min(window.innerWidth,window.innerHeight*1.5)
  let [width,setWidth] = useState(800)
  useEffect(() => {
    if (typeof window !== 'undefined'){
      window.addEventListener('resize', (e) => setWidth(Math.min(window.innerWidth,window.innerHeight*1.5)))
      setWidth(Math.min(window.innerWidth,window.innerHeight*1.5))
      console.log("set width")
      }
  }, [])
  const clickArea = (e) => {
    let right = e.title === "right arrow"
    if(right){
      setIndex(index+1%images.length)
      setCount((images.length+count+1)%images.length)
    } else {
      setIndex(images.length+(index-1%images.length))
      setCount((images.length+count-1)%images.length)
      }
  }
  const mobileClick = (right) => {
    if(right){
      setIndex(index+1%images.length)
      setCount((images.length+count+1)%images.length)
    } else {
      setIndex(images.length+(index-1%images.length))
      setCount((images.length+count-1)%images.length)
      }
  }
  let widthProp
  if(width>0)
    widthProp = {
      width: width
    }
  else
  widthProp = {
  }
  console.log(maps)
  return <div className={`hypercard hyper-${count}`}>
  <MyImagemapper map={maps[0]} {...widthProp} fillColor={"red"} src={images[index%images.length]} onClick={clickArea} imgWidth={510}/>
  <div id="mobile-buttons">
    <div id="mobile-button-left" onClick={()=>mobileClick(false)}></div>
    <div id="mobile-button-right" onClick={()=>mobileClick(true)}></div>
  </div>
  </div>
}


export default function Sample({data}) {
  const post = data.markdownRemark
  // pattern = post.frontmatter.button_background.publicURL
  const turnImages = post.frontmatter.pageturn_images ? post.frontmatter.pageturn_images : [];
  let fakeMD = {
    markdownRemark: {
      frontmatter: {
        title: "mold",
        images: post.frontmatter.pageturn_images,
        fields: post.fields
      }
    }
  }
  let images = post.frontmatter.images.map(e => e.publicURL)
  let mapData = data.allMapsJson.edges[0].node
  console.log("turnImages",turnImages)

  let content = (
    <div className="imagemap-wrapper" style={{
      // height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }}>
      <ImageMap images={images} {...mapData} slug={post.fields.slug}/>
      {turnImages.length > 0 ? <div id="hyper-turn"><Turn data={fakeMD} >
        {post.frontmatter.pageturn_images
          .map(image =>image.childImageSharp.gatsbyImageData)
          .map((imData,i) =>
          <div key={i} className="spread">
              <GatsbyImage loading={"eager"} image={getImage(imData)} alt=""/>
          </div>
          )
        }
      </Turn></div> : "" }
      </div>
  )
  return <Layout data={data}>{content}</Layout>
}
