import Hypercard from "../components/hypercard"
import { graphql } from "gatsby"

export default Hypercard

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        tags
        background_color
        pageturn_images {
          childImageSharp {
            gatsbyImageData
          }
        }
        images {
          relativePath
          publicURL
        }
        button_background {
          publicURL
        }
      }
      fields {
        slug
        table
      }
    }
    allMapsJson(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          global_areas {
            shape
            href
            title
            coords
          }
          maps {
            name
            areas {
              shape
              href
              coords
              title
            }
          }
        }
      }
    }
  }
`
